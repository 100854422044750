import icon_10 from "../assets/images/ArcInstalation.webp";
import icon_1 from "../assets/images/Easy_Maintenance.webp";
import facebook from "../assets/images/facebook.webp";
import icon_2 from "../assets/images/HighBrightness.webp";
import icon_9 from "../assets/images/HighTransparency.webp";
import icon_11 from "../assets/images/icon_11.png";
import icon_12 from "../assets/images/icon_12.png";
import instagram from "../assets/images/instagram.webp";
import icon_8 from "../assets/images/Interaction.webp";
import icon_7 from "../assets/images/KG.webp";
import icon_13 from "../assets/images/icon_13.png";
import icon_14 from "../assets/images/icon_14.png";
import location from "../assets/images/location.webp";
import message from "../assets/images/message.webp";
import phone from "../assets/images/Phone.webp";
import product1 from "../assets/images/product1.png";
import product2 from "../assets/images/product2.png";
import product3 from "../assets/images/product3.png";
import product4 from "../assets/images/product4.png";
import product5 from "../assets/images/product5.webp";
import product6 from "../assets/images/product6.webp";
import product7 from "../assets/images/product7.gif";
import product8 from "../assets/images/product8.png";
import product9 from "../assets/images/product9.png";
import product_eight_1 from "../assets/images/product_eight_1.png";
import product_eight_2 from "../assets/images/product_eight_2.png";
import product_eight_3 from "../assets/images/product_eight_3.png";
import product_eight_5 from "../assets/images/product_eight_5.png";
import product_nine_2 from "../assets/images/product_nine_2.png";
import product_nine_3 from "../assets/images/product_nine_3.png";
import product_nine_4 from "../assets/images/product_nine_4.png";
import product_nine_5 from "../assets/images/product_nine_5.png";
import product_Five_1 from "../assets/images/product_Five_1.png";
import product_Five_2 from "../assets/images/product_Five_2.png";
import product_Five_3 from "../assets/images/product_Five_3.png";
import product_Five_5 from "../assets/images/product_Five_5.png";
import product_Four_1 from "../assets/images/product_Four_1.png";
import product_Four_2 from "../assets/images/product_Four_2.png";
import product_Four_5 from "../assets/images/product_Four_5.png";
import product_Four_6 from "../assets/images/product_Four_6.png";
import product_one_1 from "../assets/images/product_one_1.png";
import product_one_2 from "../assets/images/product_one_2.png";
import product_one_3 from "../assets/images/product_one_3.webp";
import product_one_6 from "../assets/images/product_one_6.png";
import product_seven_1 from "../assets/images/product_seven_1.gif";
import product_seven_3 from "../assets/images/product_seven_3.png";
import product_seven_6 from "../assets/images/product_seven_6.png";
import product_seven_7 from "../assets/images/product_seven_7.gif";
import product_six_1 from "../assets/images/product_six_1.png";
import product_six_2 from "../assets/images/product_six_2.png";
import product_six_4 from "../assets/images/product_six_4.png";
import product_six_6 from "../assets/images/product_six_6.png";
import product_Three_1 from "../assets/images/product_Three_1.png";
import product_Three_2 from "../assets/images/product_Three_2.png";
import product_Three_3 from "../assets/images/product_Three_3.png";
import product_Three_4 from "../assets/images/product_Three_4.png";
import product_Two_1 from "../assets/images/product_Two_1.png";
import product_Two_2 from "../assets/images/product_Two_2.png";
import product_Two_4 from "../assets/images/product_Two_4.png";
import product_Two_6 from "../assets/images/product_Two_6.png";
import services1 from "../assets/images/services1.webp";
import services2 from "../assets/images/services2.png";
import services3 from "../assets/images/services3.png";
import services4 from "../assets/images/services4.png";
import youtube from "../assets/images/youtube.png";
import tiktok from "../assets/images/tiktok.webp";
import icon_5 from "../assets/images/UpTo3840Hz.webp";
import icon_3 from "../assets/images/Verylight.webp";
import icon_6 from "../assets/images/waterproof.webp";
import whatsapp from "../assets/images/whatsapp.webp";
import icon_4 from "../assets/images/WideViewing.webp";
import partners_1 from "../assets/images/partners_1.png";
import partners_2 from "../assets/images/partners_2.png";
import partners_3 from "../assets/images/partners_3.png";
import partners_4 from "../assets/images/partners_4.png";
import partners_5 from "../assets/images/partners_5.png";
import partners_6 from "../assets/images/partners_6.png";
import partners_7 from "../assets/images/partners_7.png";
import partners_8 from "../assets/images/partners_8.png";
import partners_9 from "../assets/images/partners_9.png";
import partners_10 from "../assets/images/partners_10.png";
import partners_11 from "../assets/images/partners_11.png";
import partners_12 from "../assets/images/partners_12.png";

export const productList = [
  {
    id: 8,
    image: product8,
    images: [
      { image: product_eight_1 },
      { image: product_eight_2 },
      { image: product_eight_3 },
      { image: product_eight_5 },
    ],
    descs: [25, 26, 27, 28, 5],
    icons: [13, 2, 14, 4, 5],
    title_icons: [18, 2, 19, 4, 17],
  },
  {
    id: 1,
    image: product1,
    images: [
      { image: product_one_1 },
      { image: product_one_2 },
      { image: product_one_3 },
      { image: product_one_6 },
    ],
    descs: [1, 2, 3, 4, 5],
    icons: [1, 2, 3, 4, 5],
    title_icons: [1, 2, 3, 4, 5],
  },
  {
    id: 2,
    image: product2,
    images: [
      { image: product_Two_1 },
      { image: product_Two_2 },
      { image: product_Two_4 },
      { image: product_Two_6 },
    ],
    descs: [6, 7, 3, 4, 5],
    icons: [1, 2, 6, 4, 5],
    title_icons: [1, 6, 7, 4, 5],
  },
  {
    id: 3,
    image: product3,
    images: [
      { image: product_Three_1 },
      { image: product_Three_2 },
      { image: product_Three_3 },
      { image: product_Three_4 },
    ],
    descs: [8, 9, 10, 11, 5],
    icons: [1, 2, 6, 7, 8],
    title_icons: [1, 2, 8, 9, 10],
  },
  {
    id: 4,
    image: product4,
    images: [
      { image: product_Four_1 },
      { image: product_Four_2 },
      { image: product_Four_5 },
      { image: product_Four_6 },
    ],
    descs: [8, 12, 13, 14, 5],
    icons: [1, 2, 6, 9, 4],
    title_icons: [1, 2, 7, 11, 4],
  },
  {
    id: 5,
    image: product5,
    images: [
      { image: product_Five_1 },
      { image: product_Five_2 },
      { image: product_Five_3 },
      { image: product_Five_5 },
    ],
    descs: [8, 15, 13, 14, 5],
    icons: [1, 2, 6, 10, 4],
    title_icons: [1, 2, 7, 12, 4],
  },
  {
    id: 6,
    image: product6,
    images: [
      { image: product_six_1 },
      { image: product_six_2 },
      { image: product_six_4 },
      { image: product_six_6 },
    ],
    descs: [16, 10, 17, 18, 19],
    icons: [1, 2, 6, 9, 4],
    title_icons: [1, 2, 7, 11, 4],
  },
  {
    id: 7,
    image: product7,
    images: [
      { image: product_seven_1 },
      { image: product_seven_3 },
      { image: product_seven_6 },
      { image: product_seven_7 },
    ],
    descs: [20, 21, 22, 23, 24],
    icons: [1, 12, 11, 4, 10],
    title_icons: [1, 16, 15, 14, 13],
  },

  {
    id: 9,
    image: product9,
    images: [
      { image: product_nine_2 },
      { image: product_nine_3 },
      { image: product_nine_4 },
      { image: product_nine_5 },
    ],
    descs: [29, 30, 31, 32, 33],
    icons: [13, 2, 14, 4, 5],
    title_icons: [18, 2, 19, 4, 17],
  },
];
export const iconsList = {
  icon_1: icon_1,
  icon_2: icon_2,
  icon_3: icon_3,
  icon_4: icon_4,
  icon_5: icon_5,
  icon_6: icon_6,
  icon_7: icon_7,
  icon_8: icon_8,
  icon_9: icon_9,
  icon_10: icon_10,
  icon_11: icon_11,
  icon_12: icon_12,
  icon_13: icon_13,
  icon_14: icon_14,
};
export const servicesList = [
  { image: services1 },
  { image: services2 },
  { image: services3 },
  { image: services4 },
];
export const partnersOfSuccessList = [
  partners_1,
  partners_2,
  partners_3,
  partners_4,
  partners_5,
  partners_6,
  partners_7,
  partners_8,
  partners_9,
  partners_10,
  partners_11,
  partners_12,
];

export let phoneNumber;

export const ContactList = [
  { link: "tel:+971504681550", image: phone },
  { link: "mailto:mdsbaih@outlook.com", image: message },
  { link: "", image: whatsapp },
  { link: "https://www.instagram.com/brilliant.grp/", image: instagram },
  { link: "https://www.facebook.com/BrilliantDataShow/", image: facebook },
  { link: "https://www.tiktok.com/@brilliant.tech", image: tiktok },
  { link: "https://www.youtube.com/@brilliantdatashow3886", image: youtube },
  { link: "https://maps.app.goo.gl/LC1E3ZGQUT891XdG8", image: location },
];
