import React, { useEffect } from "react";
import { Title } from "../../components";
import { content } from "../../contexts/useLang";
import { Container } from "../../components/GridSystem";
import logo from "../../assets/images/1.png";
import Aos from "aos";

const AboutUs = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <Container>
        <Title title="about_us" />
        <div
          data-aos="fade-left"
          data-aos-delay="50"
          data-aos-duration="750"
          className="md:h-[200px] py-5 border border-Dark rounded-3xl px-8  mx-auto bg-Light flex items-center justify-between max-md:items-start"
        >
          <div className="max-md:order-2">
            <Container>
              <div className="md:text-lg ">
                {" "}
                <img
                  src={logo}
                  alt="Brilliant Technology "
                  className="w-[70px] inline md:hidden"
                />
                {content.des_about_us_one}
              </div>
              <br />
              <div className="md:text-lg">{content.des_about_us_tow}</div>
            </Container>
          </div>
          <div className="max-md:order-1 mx-auto max-md:hidden">
            <img
              src={logo}
              alt="Brilliant Technology "
              className="w-[200px] object-cover "
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
