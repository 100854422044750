import { Col, Container, Row } from "../../components/GridSystem";
import { content, langDiv } from "../../contexts/useLang";
import logo from "../../assets/images/1.png";
import { Link } from "react-router-dom";
const Navbar = ({ setTarget }) => {
  return (
    <div className="bg-Light py-2 w-full max-md:text-[7px]">
      <Container>
        <Row className="items-center">
          <Col sm={2} md={4}>
            <div className="max-md:mx-auto ">
              <Link to="/">
                <img
                  src={logo}
                  alt="Brilliant Technology"
                  className=" max-md:w-5 "
                />
              </Link>
            </div>
          </Col>
          {/* <Col sm={2} className="flex justify-end md:hidden">
            <button
              onClick={() => {
                const newLanguage =
                  (localStorage.getItem("language") === "ar" &&
                    localStorage.getItem("language")) ||
                  (langDiv === "ar" && !localStorage.getItem("language"))
                    ? "en"
                    : "ar";
                window.localStorage.setItem("language", newLanguage);
                window.location.reload();
              }}
              className="border border-Dark rounded-md py-1 px-3 hover:text-Main transition-all font-semibold hover:bg-Secondary"
            >
              {(localStorage.getItem("language") === "ar" &&
                localStorage.getItem("language")) ||
              (langDiv === "ar" && !localStorage.getItem("language"))
                ? "English"
                : "العربية"}
            </button>
          </Col> */}
          <Col sm={8} md={4}>
            <ul className="flex items-center justify-center gap-5 max-md:mt-2 ">
              {["about_us", "products", "services", "contact_us"].map(
                (e, i) => (
                  <li
                    key={i}
                    onClick={() => setTarget(e)}
                    className={"group w-fit hover:text-Secondary font-semibold"}
                  >
                    {content[e]}
                  </li>
                )
              )}
            </ul>
          </Col>
          <Col sm={2} md={4} className="flex justify-end  ">
            <button
              onClick={() => {
                const newLanguage =
                  (localStorage.getItem("language") === "ar" &&
                    localStorage.getItem("language")) ||
                  (langDiv === "ar" && !localStorage.getItem("language"))
                    ? "en"
                    : "ar";
                window.localStorage.setItem("language", newLanguage);
                window.location.reload();
              }}
              className="border border-Dark rounded-md py-1 px-3 hover:text-Main transition-all font-semibold hover:bg-Secondary"
            >
              {(localStorage.getItem("language") === "ar" &&
                localStorage.getItem("language")) ||
              (langDiv === "ar" && !localStorage.getItem("language"))
                ? "English"
                : "العربية"}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;
