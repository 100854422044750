import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import next from "../../assets/images/pngwing.png";
import { Title } from "../../components";
import { Col, Container, Row } from "../../components/GridSystem";
import { partnersOfSuccessList } from "../../contexts/Lists";

const PartnersOfSuccess = () => {
  return (
    <div>
      <Container>
        <Title title="partners_of_success" />
        <div className=" border border-Dark rounded-3xl px-1 py-4  mx-auto bg-Light ">
          <Row className="max-md:hidden">
            <Col md={12} className="relative px-1">
              <button className="swiper-button-prev-custom max-md:hidden absolute -left-8 max-md:-left-5 top-1/2 transform -translate-y-1/2 z-10">
                <img src={next} alt="" className="rotate-180 w-12" />
              </button>
              <Swiper
                modules={[Navigation]}
                navigation={{
                  prevEl: ".swiper-button-prev-custom",
                  nextEl: ".swiper-button-next-custom",
                }}
                slidesPerView={7}
                breakpoints={{
                  900: {
                    slidesPerView: 7,
                  },
                  500: {
                    slidesPerView: 5,
                  },
                  0: {
                    slidesPerView: 2.5,
                  },
                }}
                spaceBetween={5}
                pagination={{ clickable: true }}
              >
                {partnersOfSuccessList.map((e, i) => (
                  <SwiperSlide key={i}>
                    <div>
                      <img className="object-contain" src={e} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <button className="swiper-button-next-custom absolute max-md:hidden -right-8 max-md:-right-5 top-1/2 transform -translate-y-1/2 z-10">
                <img src={next} alt="" className=" w-12" />
              </button>
            </Col>
          </Row>
          <Row className="md:hidden">
            {partnersOfSuccessList.map((e, i) => (
              <Col col={2} key={i} className="p-1">
                <img className="object-cover" src={e} alt="" />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default PartnersOfSuccess;
